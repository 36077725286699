/* purgecss start ignore */
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

@import './root.css';
@import './fonts.css';
@import './common.css';
@import './ant-override.css';
@import './nprogress.css';

html,
body {
  font-family: 'Avenir Next', Arial, Helvetica, sans-serif;
}

*,
:after,
:before {
  border: 0 solid var(--grey-light);
}

button {
  background: transparent;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

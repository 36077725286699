.container{
  width: 100%;
}

@media (min-width: 640px){
  .container{
    max-width: 640px;
  }
}

@media (min-width: 768px){
  .container{
    max-width: 768px;
  }
}

@media (min-width: 1024px){
  .container{
    max-width: 1024px;
  }
}

@media (min-width: 1280px){
  .container{
    max-width: 1280px;
  }
}

.bg-transparent{
  background-color: transparent !important;
}

.bg-white{
  background-color: var(--white) !important;
}

.bg-red{
  background-color: var(--red) !important;
}

.bg-orange{
  background-color: var(--orange) !important;
}

.bg-yellow{
  background-color: var(--yellow) !important;
}

.bg-blue{
  background-color: var(--blue) !important;
}

.bg-primary{
  background-color: var(--primary) !important;
}

.bg-secondary{
  background-color: var(--secondary) !important;
}

.bg-grey-darkest{
  background-color: var(--grey-darkest) !important;
}

.bg-grey-darker{
  background-color: var(--grey-darker) !important;
}

.bg-grey-dark{
  background-color: var(--grey-dark) !important;
}

.bg-grey{
  background-color: var(--grey) !important;
}

.bg-grey-light{
  background-color: var(--grey-light) !important;
}

.bg-grey-lighter{
  background-color: var(--grey-lighter) !important;
}

.bg-grey-lightest{
  background-color: var(--grey-lightest) !important;
}

.bg-grey-disabled{
  background-color: var(--grey-disabled) !important;
}

.bg-red-lighter{
  background-color: var(--red-lighter) !important;
}

.bg-red-lightest{
  background-color: var(--red-lightest) !important;
}

.bg-orange-lightest{
  background-color: var(--orange-lightest) !important;
}

.bg-yellow-lighter{
  background-color: var(--yellow-lighter) !important;
}

.bg-green-lighter{
  background-color: var(--green-lighter) !important;
}

.bg-blue-lighter{
  background-color: var(--blue-lighter) !important;
}

.bg-cyan-darkest{
  background-color: var(--cyan-darkest) !important;
}

.hover\:bg-white:hover{
  background-color: var(--white) !important;
}

.hover\:bg-primary:hover{
  background-color: var(--primary) !important;
}

.hover\:bg-secondary:hover{
  background-color: var(--secondary) !important;
}

.hover\:bg-grey-dark:hover{
  background-color: var(--grey-dark) !important;
}

.hover\:bg-grey:hover{
  background-color: var(--grey) !important;
}

.hover\:bg-grey-lighter:hover{
  background-color: var(--grey-lighter) !important;
}

.bg-center{
  background-position: center !important;
}

.bg-no-repeat{
  background-repeat: no-repeat !important;
}

.bg-cover{
  background-size: cover !important;
}

.border-collapse{
  border-collapse: collapse !important;
}

.border-transparent{
  border-color: transparent !important;
}

.border-red{
  border-color: var(--red) !important;
}

.border-orange{
  border-color: var(--orange) !important;
}

.border-green{
  border-color: var(--green) !important;
}

.border-blue{
  border-color: var(--blue) !important;
}

.border-primary{
  border-color: var(--primary) !important;
}

.border-secondary{
  border-color: var(--secondary) !important;
}

.border-grey-darkest{
  border-color: var(--grey-darkest) !important;
}

.border-grey-darker{
  border-color: var(--grey-darker) !important;
}

.border-grey-dark{
  border-color: var(--grey-dark) !important;
}

.border-grey{
  border-color: var(--grey) !important;
}

.border-grey-light{
  border-color: var(--grey-light) !important;
}

.border-grey-lighter{
  border-color: var(--grey-lighter) !important;
}

.border-grey-lightest{
  border-color: var(--grey-lightest) !important;
}

.border-green-dark{
  border-color: var(--primary) !important;
}

.hover\:border-yellow:hover{
  border-color: var(--yellow) !important;
}

.hover\:border-primary:hover{
  border-color: var(--primary) !important;
}

.hover\:border-secondary:hover{
  border-color: var(--secondary) !important;
}

.rounded-none{
  border-radius: 0 !important;
}

.rounded-sm{
  border-radius: 0.125rem !important;
}

.rounded{
  border-radius: 0.25rem !important;
}

.rounded-full{
  border-radius: 9999px !important;
}

.rounded-r-none{
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-b-none{
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-l-none{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-r-sm{
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.rounded-t{
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-br-sm{
  border-bottom-right-radius: 0.125rem !important;
}

.rounded-bl-sm{
  border-bottom-left-radius: 0.125rem !important;
}

.border-dashed{
  border-style: dashed !important;
}

.border-none{
  border-style: none !important;
}

.border-0{
  border-width: 0 !important;
}

.border{
  border-width: 1px !important;
}

.border-t-0{
  border-top-width: 0 !important;
}

.border-r-0{
  border-right-width: 0 !important;
}

.border-b-0{
  border-bottom-width: 0 !important;
}

.border-l-0{
  border-left-width: 0 !important;
}

.border-r-2{
  border-right-width: 2px !important;
}

.border-b-2{
  border-bottom-width: 2px !important;
}

.border-r-4{
  border-right-width: 4px !important;
}

.border-t{
  border-top-width: 1px !important;
}

.border-r{
  border-right-width: 1px !important;
}

.border-b{
  border-bottom-width: 1px !important;
}

.border-l{
  border-left-width: 1px !important;
}

.cursor-pointer{
  cursor: pointer !important;
}

.cursor-not-allowed{
  cursor: not-allowed !important;
}

.block{
  display: block !important;
}

.inline-block{
  display: inline-block !important;
}

.inline{
  display: inline !important;
}

.flex{
  display: flex !important;
}

.inline-flex{
  display: inline-flex !important;
}

.table{
  display: table !important;
}

.hidden{
  display: none !important;
}

.flex-row{
  flex-direction: row !important;
}

.flex-row-reverse{
  flex-direction: row-reverse !important;
}

.flex-col{
  flex-direction: column !important;
}

.flex-wrap{
  flex-wrap: wrap !important;
}

.flex-no-wrap{
  flex-wrap: nowrap !important;
}

.items-start{
  align-items: flex-start !important;
}

.items-end{
  align-items: flex-end !important;
}

.items-center{
  align-items: center !important;
}

.content-center{
  align-content: center !important;
}

.self-center{
  align-self: center !important;
}

.justify-start{
  justify-content: flex-start !important;
}

.justify-end{
  justify-content: flex-end !important;
}

.justify-center{
  justify-content: center !important;
}

.justify-between{
  justify-content: space-between !important;
}

.justify-around{
  justify-content: space-around !important;
}

.flex-1{
  flex: 1 1 0% !important;
}

.flex-auto{
  flex: 1 1 auto !important;
}

.flex-none{
  flex: none !important;
}

.flex-grow{
  flex-grow: 1 !important;
}

.flex-shrink{
  flex-shrink: 1 !important;
}

.float-right{
  float: right !important;
}

.font-normal{
  font-weight: 400 !important;
}

.font-medium{
  font-weight: 500 !important;
}

.font-semibold{
  font-weight: 600 !important;
}

.font-bold{
  font-weight: 700 !important;
}

.h-3{
  height: 0.75rem !important;
}

.h-5{
  height: 1.25rem !important;
}

.h-6{
  height: 1.5rem !important;
}

.h-8{
  height: 2rem !important;
}

.h-9{
  height: 2.25rem !important;
}

.h-10{
  height: 2.5rem !important;
}

.h-14{
  height: 3.5rem !important;
}

.h-16{
  height: 4rem !important;
}

.h-auto{
  height: auto !important;
}

.h-px{
  height: 1px !important;
}

.h-full{
  height: 100% !important;
}

.h-screen{
  height: 100vh !important;
}

.text-xs{
  font-size: .75rem !important;
}

.text-sm{
  font-size: .875rem !important;
}

.text-base{
  font-size: 1rem !important;
}

.text-lg{
  font-size: 1.125rem !important;
}

.text-xl{
  font-size: 1.25rem !important;
}

.text-2xl{
  font-size: 1.5rem !important;
}

.text-3xl{
  font-size: 1.875rem !important;
}

.text-5xl{
  font-size: 3rem !important;
}

.text-3xs{
  font-size: .625rem !important;
}

.text-2xs{
  font-size: .6875rem !important;
}

.leading-none{
  line-height: 1 !important;
}

.leading-tight{
  line-height: 1.25 !important;
}

.leading-normal{
  line-height: 1.5 !important;
}

.leading-loose{
  line-height: 2 !important;
}

.list-none{
  list-style-type: none !important;
}

.m-0{
  margin: 0 !important;
}

.m-4{
  margin: 1rem !important;
}

.m-auto{
  margin: auto !important;
}

.my-0{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1{
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.mx-1{
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.my-2{
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.mx-2{
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.my-3{
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.mx-3{
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.my-4{
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx-4{
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-5{
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.my-6{
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mx-6{
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.my-8{
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.mx-8{
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-auto{
  margin-left: auto !important;
  margin-right: auto !important;
}

.-mx-1{
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.-mx-2{
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.-mx-3{
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.-my-4{
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.-mx-4{
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.-mx-5{
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.-mx-6{
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mt-0{
  margin-top: 0 !important;
}

.mr-0{
  margin-right: 0 !important;
}

.mb-0{
  margin-bottom: 0 !important;
}

.ml-0{
  margin-left: 0 !important;
}

.mt-1{
  margin-top: 0.25rem !important;
}

.mr-1{
  margin-right: 0.25rem !important;
}

.mb-1{
  margin-bottom: 0.25rem !important;
}

.ml-1{
  margin-left: 0.25rem !important;
}

.mt-2{
  margin-top: 0.5rem !important;
}

.mr-2{
  margin-right: 0.5rem !important;
}

.mb-2{
  margin-bottom: 0.5rem !important;
}

.ml-2{
  margin-left: 0.5rem !important;
}

.mt-3{
  margin-top: 0.75rem !important;
}

.mr-3{
  margin-right: 0.75rem !important;
}

.mb-3{
  margin-bottom: 0.75rem !important;
}

.ml-3{
  margin-left: 0.75rem !important;
}

.mt-4{
  margin-top: 1rem !important;
}

.mr-4{
  margin-right: 1rem !important;
}

.mb-4{
  margin-bottom: 1rem !important;
}

.ml-4{
  margin-left: 1rem !important;
}

.mt-5{
  margin-top: 1.25rem !important;
}

.mr-5{
  margin-right: 1.25rem !important;
}

.mb-5{
  margin-bottom: 1.25rem !important;
}

.ml-5{
  margin-left: 1.25rem !important;
}

.mt-6{
  margin-top: 1.5rem !important;
}

.mr-6{
  margin-right: 1.5rem !important;
}

.mb-6{
  margin-bottom: 1.5rem !important;
}

.ml-6{
  margin-left: 1.5rem !important;
}

.mt-7{
  margin-top: 1.75rem !important;
}

.mb-7{
  margin-bottom: 1.75rem !important;
}

.mt-8{
  margin-top: 2rem !important;
}

.mr-8{
  margin-right: 2rem !important;
}

.mb-8{
  margin-bottom: 2rem !important;
}

.ml-8{
  margin-left: 2rem !important;
}

.mt-10{
  margin-top: 2.5rem !important;
}

.mr-10{
  margin-right: 2.5rem !important;
}

.mb-10{
  margin-bottom: 2.5rem !important;
}

.mt-12{
  margin-top: 3rem !important;
}

.mr-12{
  margin-right: 3rem !important;
}

.mb-12{
  margin-bottom: 3rem !important;
}

.ml-12{
  margin-left: 3rem !important;
}

.mt-16{
  margin-top: 4rem !important;
}

.mt-20{
  margin-top: 5rem !important;
}

.mr-20{
  margin-right: 5rem !important;
}

.ml-20{
  margin-left: 5rem !important;
}

.mt-24{
  margin-top: 6rem !important;
}

.ml-auto{
  margin-left: auto !important;
}

.-mr-1{
  margin-right: -0.25rem !important;
}

.-mt-2{
  margin-top: -0.5rem !important;
}

.-mb-2{
  margin-bottom: -0.5rem !important;
}

.-ml-3{
  margin-left: -0.75rem !important;
}

.-mt-4{
  margin-top: -1rem !important;
}

.-mb-4{
  margin-bottom: -1rem !important;
}

.-ml-4{
  margin-left: -1rem !important;
}

.-mt-5{
  margin-top: -1.25rem !important;
}

.max-w-xs{
  max-width: 20rem !important;
}

.max-w-full{
  max-width: 100% !important;
}

.min-h-screen{
  min-height: 100vh !important;
}

.object-cover{
  object-fit: cover !important;
}

.opacity-0{
  opacity: 0 !important;
}

.opacity-25{
  opacity: 0.25 !important;
}

.opacity-50{
  opacity: 0.50 !important;
}

.opacity-75{
  opacity: 0.75 !important;
}

.outline-none{
  outline: 0 !important;
}

.overflow-auto{
  overflow: auto !important;
}

.overflow-hidden{
  overflow: hidden !important;
}

.overflow-visible{
  overflow: visible !important;
}

.overflow-y-auto{
  overflow-y: auto !important;
}

.overflow-x-scroll{
  overflow-x: scroll !important;
}

.p-0{
  padding: 0 !important;
}

.p-1{
  padding: 0.25rem !important;
}

.p-2{
  padding: 0.5rem !important;
}

.p-4{
  padding: 1rem !important;
}

.p-6{
  padding: 1.5rem !important;
}

.p-8{
  padding: 2rem !important;
}

.p-10{
  padding: 2.5rem !important;
}

.p-20{
  padding: 5rem !important;
}

.py-0{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-0{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-1{
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.px-1{
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.py-2{
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.px-2{
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.py-3{
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.px-3{
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.py-4{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-4{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-5{
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.px-5{
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.py-6{
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.px-6{
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-8{
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.px-8{
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-10{
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.px-10{
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.py-12{
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-20{
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-0{
  padding-top: 0 !important;
}

.pr-0{
  padding-right: 0 !important;
}

.pb-0{
  padding-bottom: 0 !important;
}

.pl-0{
  padding-left: 0 !important;
}

.pt-1{
  padding-top: 0.25rem !important;
}

.pr-1{
  padding-right: 0.25rem !important;
}

.pb-1{
  padding-bottom: 0.25rem !important;
}

.pr-2{
  padding-right: 0.5rem !important;
}

.pb-2{
  padding-bottom: 0.5rem !important;
}

.pl-2{
  padding-left: 0.5rem !important;
}

.pt-3{
  padding-top: 0.75rem !important;
}

.pr-3{
  padding-right: 0.75rem !important;
}

.pb-3{
  padding-bottom: 0.75rem !important;
}

.pl-3{
  padding-left: 0.75rem !important;
}

.pt-4{
  padding-top: 1rem !important;
}

.pr-4{
  padding-right: 1rem !important;
}

.pb-4{
  padding-bottom: 1rem !important;
}

.pl-4{
  padding-left: 1rem !important;
}

.pb-5{
  padding-bottom: 1.25rem !important;
}

.pl-5{
  padding-left: 1.25rem !important;
}

.pt-6{
  padding-top: 1.5rem !important;
}

.pr-6{
  padding-right: 1.5rem !important;
}

.pb-6{
  padding-bottom: 1.5rem !important;
}

.pl-6{
  padding-left: 1.5rem !important;
}

.pt-7{
  padding-top: 1.75rem !important;
}

.pb-8{
  padding-bottom: 2rem !important;
}

.pl-8{
  padding-left: 2rem !important;
}

.pl-9{
  padding-left: 2.25rem !important;
}

.pl-10{
  padding-left: 2.5rem !important;
}

.pr-12{
  padding-right: 3rem !important;
}

.pb-12{
  padding-bottom: 3rem !important;
}

.pt-24{
  padding-top: 6rem !important;
}

.pt-32{
  padding-top: 8rem !important;
}

.pointer-events-none{
  pointer-events: none !important;
}

.static{
  position: static !important;
}

.fixed{
  position: fixed !important;
}

.absolute{
  position: absolute !important;
}

.relative{
  position: relative !important;
}

.sticky{
  position: sticky !important;
}

.inset-0{
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.inset-y-0{
  top: 0 !important;
  bottom: 0 !important;
}

.top-0{
  top: 0 !important;
}

.right-0{
  right: 0 !important;
}

.bottom-0{
  bottom: 0 !important;
}

.left-0{
  left: 0 !important;
}

.shadow-sm{
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}

.shadow{
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.shadow-none{
  box-shadow: none !important;
}

.hover\:shadow-lg:hover{
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.text-left{
  text-align: left !important;
}

.text-center{
  text-align: center !important;
}

.text-right{
  text-align: right !important;
}

.text-black{
  color: var(--black) !important;
}

.text-white{
  color: var(--white) !important;
}

.text-red{
  color: var(--red) !important;
}

.text-orange{
  color: var(--orange) !important;
}

.text-yellow{
  color: var(--yellow) !important;
}

.text-green{
  color: var(--green) !important;
}

.text-blue{
  color: var(--blue) !important;
}

.text-purple{
  color: var(--purple) !important;
}

.text-primary{
  color: var(--primary) !important;
}

.text-secondary{
  color: var(--secondary) !important;
}

.text-black-lightest{
  color: var(--black-lightest) !important;
}

.text-grey-darkest{
  color: var(--grey-darkest) !important;
}

.text-grey-darker{
  color: var(--grey-darker) !important;
}

.hover\:text-yellow:hover{
  color: var(--yellow) !important;
}

.hover\:text-primary:hover{
  color: var(--primary) !important;
}

.hover\:text-secondary:hover{
  color: var(--secondary) !important;
}

.italic{
  font-style: italic !important;
}

.uppercase{
  text-transform: uppercase !important;
}

.lowercase{
  text-transform: lowercase !important;
}

.capitalize{
  text-transform: capitalize !important;
}

.normal-case{
  text-transform: none !important;
}

.underline{
  text-decoration: underline !important;
}

.ordinal{
  --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/) !important;
  --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/) !important;
  --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/) !important;
  --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/) !important;
  --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/) !important;
  font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction) !important;
}

.ordinal{
  --font-variant-numeric-ordinal: ordinal !important;
}

.tracking-normal{
  letter-spacing: 0 !important;
}

.tracking-wide{
  letter-spacing: 0.025em !important;
}

.visible{
  visibility: visible !important;
}

.whitespace-normal{
  white-space: normal !important;
}

.break-words{
  overflow-wrap: break-word !important;
}

.break-all{
  word-break: break-all !important;
}

.truncate{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.w-3{
  width: 0.75rem !important;
}

.w-5{
  width: 1.25rem !important;
}

.w-6{
  width: 1.5rem !important;
}

.w-8{
  width: 2rem !important;
}

.w-10{
  width: 2.5rem !important;
}

.w-18{
  width: 4.5rem !important;
}

.w-24{
  width: 6rem !important;
}

.w-32{
  width: 8rem !important;
}

.w-40{
  width: 10rem !important;
}

.w-48{
  width: 12rem !important;
}

.w-64{
  width: 16rem !important;
}

.w-auto{
  width: auto !important;
}

.w-1\/2{
  width: 50% !important;
}

.w-1\/3{
  width: 33.333333% !important;
}

.w-1\/4{
  width: 25% !important;
}

.w-3\/4{
  width: 75% !important;
}

.w-1\/5{
  width: 20% !important;
}

.w-2\/5{
  width: 40% !important;
}

.w-1\/6{
  width: 16.666667% !important;
}

.w-5\/6{
  width: 83.333333% !important;
}

.w-1\/12{
  width: 8.333333% !important;
}

.w-11\/12{
  width: 91.666667% !important;
}

.w-full{
  width: 100% !important;
}

.z-0{
  z-index: 0 !important;
}

.z-10{
  z-index: 10 !important;
}

.z-20{
  z-index: 20 !important;
}

.z-50{
  z-index: 50 !important;
}

.transform{
  --transform-translate-x: 0 !important;
  --transform-translate-y: 0 !important;
  --transform-rotate: 0 !important;
  --transform-skew-x: 0 !important;
  --transform-skew-y: 0 !important;
  --transform-scale-x: 1 !important;
  --transform-scale-y: 1 !important;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
}

.transition{
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
}

@keyframes spin{
  to{
    transform: rotate(360deg);
  }
}

@keyframes ping{
  75%, 100%{
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse{
  50%{
    opacity: .5;
  }
}

@keyframes bounce{
  0%, 100%{
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@import './root.css';

@import './fonts.css';

@import './common.css';

@import './ant-override.css';

@import './nprogress.css';

html,
body {
  font-family: 'Avenir Next', Arial, Helvetica, sans-serif;
}

*,
:after,
:before {
  border: 0 solid var(--grey-light);
}

button {
  background: transparent;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

@media (min-width: 640px){
  .sm\:container{
    width: 100%;
  }

  @media (min-width: 640px){
    .sm\:container{
      max-width: 640px;
    }
  }

  @media (min-width: 768px){
    .sm\:container{
      max-width: 768px;
    }
  }

  @media (min-width: 1024px){
    .sm\:container{
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px){
    .sm\:container{
      max-width: 1280px;
    }
  }

  .sm\:border-grey-lighter{
    border-color: var(--grey-lighter) !important;
  }

  .sm\:border-r{
    border-right-width: 1px !important;
  }

  .sm\:block{
    display: block !important;
  }

  .sm\:mx-8{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .sm\:ml-8{
    margin-left: 2rem !important;
  }

  .sm\:truncate{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .sm\:w-32{
    width: 8rem !important;
  }

  .sm\:w-1\/2{
    width: 50% !important;
  }

  .sm\:w-1\/3{
    width: 33.333333% !important;
  }

  .sm\:w-1\/4{
    width: 25% !important;
  }

  .sm\:w-full{
    width: 100% !important;
  }
}

@media (min-width: 768px){
  .md\:container{
    width: 100%;
  }

  @media (min-width: 640px){
    .md\:container{
      max-width: 640px;
    }
  }

  @media (min-width: 768px){
    .md\:container{
      max-width: 768px;
    }
  }

  @media (min-width: 1024px){
    .md\:container{
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px){
    .md\:container{
      max-width: 1280px;
    }
  }

  .md\:block{
    display: block !important;
  }

  .md\:inline{
    display: inline !important;
  }

  .md\:flex-row{
    flex-direction: row !important;
  }

  .md\:flex-no-wrap{
    flex-wrap: nowrap !important;
  }

  .md\:-mx-2{
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .md\:mt-0{
    margin-top: 0 !important;
  }

  .md\:mb-0{
    margin-bottom: 0 !important;
  }

  .md\:mr-3{
    margin-right: 0.75rem !important;
  }

  .md\:mr-4{
    margin-right: 1rem !important;
  }

  .md\:ml-4{
    margin-left: 1rem !important;
  }

  .md\:ml-12{
    margin-left: 3rem !important;
  }

  .md\:px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .md\:pr-0{
    padding-right: 0 !important;
  }

  .md\:pb-0{
    padding-bottom: 0 !important;
  }

  .md\:pr-6{
    padding-right: 1.5rem !important;
  }

  .md\:w-32{
    width: 8rem !important;
  }

  .md\:w-40{
    width: 10rem !important;
  }

  .md\:w-64{
    width: 16rem !important;
  }

  .md\:w-auto{
    width: auto !important;
  }

  .md\:w-1\/2{
    width: 50% !important;
  }

  .md\:w-1\/3{
    width: 33.333333% !important;
  }

  .md\:w-2\/3{
    width: 66.666667% !important;
  }

  .md\:w-1\/4{
    width: 25% !important;
  }

  .md\:w-3\/4{
    width: 75% !important;
  }

  .md\:w-1\/5{
    width: 20% !important;
  }

  .md\:w-2\/5{
    width: 40% !important;
  }

  .md\:w-3\/5{
    width: 60% !important;
  }

  .md\:w-full{
    width: 100% !important;
  }
}

@media (min-width: 1024px){
  .lg\:container{
    width: 100%;
  }

  @media (min-width: 640px){
    .lg\:container{
      max-width: 640px;
    }
  }

  @media (min-width: 768px){
    .lg\:container{
      max-width: 768px;
    }
  }

  @media (min-width: 1024px){
    .lg\:container{
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px){
    .lg\:container{
      max-width: 1280px;
    }
  }

  .lg\:block{
    display: block !important;
  }

  .lg\:hidden{
    display: none !important;
  }

  .lg\:flex-1{
    flex: 1 1 0% !important;
  }

  .lg\:flex-none{
    flex: none !important;
  }

  .lg\:mt-0{
    margin-top: 0 !important;
  }

  .lg\:ml-3{
    margin-left: 0.75rem !important;
  }

  .lg\:py-0{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lg\:w-40{
    width: 10rem !important;
  }

  .lg\:w-48{
    width: 12rem !important;
  }

  .lg\:w-56{
    width: 14rem !important;
  }

  .lg\:w-auto{
    width: auto !important;
  }

  .lg\:w-1\/2{
    width: 50% !important;
  }

  .lg\:w-1\/3{
    width: 33.333333% !important;
  }

  .lg\:w-2\/3{
    width: 66.666667% !important;
  }

  .lg\:w-1\/4{
    width: 25% !important;
  }

  .lg\:w-3\/4{
    width: 75% !important;
  }

  .lg\:w-1\/5{
    width: 20% !important;
  }

  .lg\:w-2\/5{
    width: 40% !important;
  }

  .lg\:w-3\/5{
    width: 60% !important;
  }
}

@media (min-width: 1280px){
  .xl\:container{
    width: 100%;
  }

  @media (min-width: 640px){
    .xl\:container{
      max-width: 640px;
    }
  }

  @media (min-width: 768px){
    .xl\:container{
      max-width: 768px;
    }
  }

  @media (min-width: 1024px){
    .xl\:container{
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px){
    .xl\:container{
      max-width: 1280px;
    }
  }

  .xl\:mt-0{
    margin-top: 0 !important;
  }

  .xl\:w-64{
    width: 16rem !important;
  }

  .xl\:w-1\/2{
    width: 50% !important;
  }

  .xl\:w-1\/3{
    width: 33.333333% !important;
  }

  .xl\:w-1\/4{
    width: 25% !important;
  }

  .xl\:w-1\/5{
    width: 20% !important;
  }

  .xl\:w-2\/5{
    width: 40% !important;
  }

  .xl\:w-1\/6{
    width: 16.666667% !important;
  }
}

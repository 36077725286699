.ant-layout {
  background-color: var(--grey-lighter) !important;
}

.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}

.ant-tabs-ink-bar {
  height: 4px !important;
  border-radius: 2px 2px 0px 0px;
}

.ant-tabs-nav .ant-tabs-tab {
  font-size: 16px;
  font-weight: bold !important;
}

.ant-modal-header {
  height: 56px;
  /* border-radius: 8px 8px 0 0 !important;
  padding-top: 12px !important;*/
  border-bottom: none !important;
}

.ant-modal-footer {
  height: 56px;
}

.ant-form-item-label {
  text-transform: uppercase;
  line-height: 1 !important;
  padding: 0 0 6px !important;
}

.has-error .ant-select-selection {
  border-color: var(--red) !important  ;
}

.ant-form label {
  font-weight: 500;
  color: var(--secondary) !important;
  font-size: 11px !important;
}

.ant-form-item-required::before {
  font-size: 11px !important;
}

.ant-radio-button-wrapper {
  text-transform: uppercase !important;
  font-size: 11px !important;
  font-weight: 500;
  color: var(--secondary) !important;
  background-color: var(--grey) !important;
  border-color: var(--grey) !important;
  height: 30px !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  line-height: 30px !important;
}

.ant-radio-group {
  font-size: 0 !important;
  line-height: 1em !important;
}

.ant-radio-button-wrapper-checked {
  border-color: var(--grey) !important;
  color: var(--primary) !important;
  background-color: var(--white) !important;
  box-shadow: none !important;
  font-weight: 600 !important;
}

.ant-radio-button-wrapper-checked::before {
  background-color: var(--grey) !important;
}

.ant-radio-button-wrapper a {
  color: var(--grey-darkest) !important;
}

.ant-radio-button-wrapper-checked a {
  color: var(--white) !important;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 2px 0 0 2px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}

.ant-table-tbody > tr > td {
  color: var(--secondary);
}

.ant-table-thead > tr > th {
  color: var(--grey-darker) !important;
}

.ant-table-expanded-row {
  background-color: var(--white) !important;
}

.ant-table-expanded-row td > .ant-table-wrapper {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ant-table-row-expand-icon-cell,
.ant-table-expand-icon-th {
  padding: 8px 0px 8px 24px !important;
}

.ant-table-pagination.ant-pagination {
  /* margin: 8px 24px 8px auto !important; */
  margin-right: 12px !important;
  margin-top: 27px !important;
  margin-bottom: 27px !important;
}

.ant-pagination {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-item {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-pagination-item {
  width: 32px;
  height: 32px !important;
}

.ant-pagination-prev,
.ant-pagination-next {
  width: 24px;
  height: 24px !important;
}

.ant-pagination-prev > a,
.ant-pagination-next > a {
  font-size: 14px !important;
  height: auto !important;
}

.ant-pagination.mini .ant-pagination-item {
  width: 2rem;
  height: 2rem !important;
  line-height: 2 !important;
}

.ant-pagination-item > a {
  color: var(--grey-darker);
  font-size: 14px !important;
}

.ant-pagination-item.ant-pagination-item-active > a {
  color: var(--primary);
}

.ant-pagination-item {
  font-size: 14px !important;
  /* border-color: transparent !important; */
}

.ant-pagination-item:hover,
.ant-pagination-item-active {
  border-width: 0 !important;
  border-radius: 2px !important;
  background-color: var(--grey-lighter) !important;
  color: var(--primary);
  font-weight: medium;
}

.ant-pagination-item-link {
  border: none !important;
}

.ant-checkbox-inner {
  border-color: var(--grey-dark) !important;
  border-radius: 2px !important;
}
.ant-checkbox-wrapper > span {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: var(--secondary) !important;
}

.ant-form-vertical .ant-form-explain {
  font-size: 12px;
}

.ant-tabs-tab {
  color: var(--grey-darkest);
}

.ant-modal-body {
  padding-top: 10px;
  padding-bottom: 8px;
}

.ant-form-item {
  margin-bottom: 8px !important;
}

.ant-modal-title {
  font-weight: bold !important;
}

.ant-modal-close {
  top: 8px !important;
  right: 2px !important;
}

.ant-modal-close-x {
  position: relative;
  transform: rotate(45deg);
  top: -10px;
}

.ant-drawer-close {
  transform: rotate(45deg) !important;
}

.ant-modal-close:focus,
.ant-modal-close-x:focus {
  outline: none;
}

.ant-modal-close-x svg,
.ant-drawer-close svg {
  opacity: 0;
}

.ant-modal-close-x:before,
.ant-modal-close-x:after {
  content: '';
  display: block;
  position: absolute;
  width: 14px;
  height: 2px;
  background-color: var(--grey-darkest);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-modal-close-x:after {
  height: 14px;
  width: 2px;
}

.ant-drawer-close:before,
.ant-drawer-close:after {
  content: '';
  display: block;
  position: absolute;
  width: 18px;
  height: 2px;
  background-color: var(--secondary);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-drawer-close:after {
  height: 18px;
  width: 2px;
}

.ant-drawer-close:focus {
  outline: none;
}
.ant-select-selection__choice {
  background-color: var(--grey-light) !important;
  border: none !important;
  border-radius: 2px !important;
  color: var(--secondary) !important;
}

.ant-select-selection__choice {
  padding: 0 29px 0 10px !important;
}

/*  */
/* .ant-select-disabled .ant-select-selection {
  font-size: 11px !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
} */

.ant-select-remove-icon {
  margin-right: 5px;
}

.ant-notification-notice-message {
  font-size: 14px !important;
  line-height: 1.4em !important;
}

.ant-notification-notice {
  box-shadow: none !important;
}

.ant-switch-small {
  width: 27px !important;
  height: 11px !important;
}

.ant-switch-checked {
  background-color: rgb(69, 124, 86, 0.4) !important;
}

.ant-switch-checked:after {
  background-color: var(--primary) !important;
}

.ant-switch-small:after {
  width: 16px !important;
  height: 16px !important;
  top: -4px !important;
  left: -1px !important;
}

.ant-switch-checked.ant-switch-small::after {
  left: 100% !important;
  margin-left: 2px !important;
  transform: translateX(-100%);
}

.ant-tooltip-inner {
  font-size: 10px !important;
  min-height: 24px !important;
  box-sizing: border-box !important;
  text-align: center;
}

.ant-form-explain {
  font-size: 12px !important;
}

.ant-select-dropdown-menu-item {
  color: var(--grey-darkest) !important;
}

.ant-select-dropdown-menu-item-selected {
  font-weight: normal !important;
  color: var(--primary) !important;
}

.ant-select-dropdown-menu-item-disabled {
  opacity: 0.5 !important;
}

.ant-typography {
  color: var(--grey-darker) !important;
}

.ant-input:hover {
  border-color: var(--primary) !important;
}

.ant-input:focus {
  border-color: var(--primary) !important;
}

.ant-input-disabled {
  color: var(--secondary) !important;
}

.ant-form-extra {
  font-size: 10px !important;
  color: var(--grey-dark) !important;
}

.ant-drawer-close {
  font-size: 24px !important;
  color: var(--grey-darkest) !important;
  opacity: 1 !important;
}

.ant-select-dropdown-menu-item {
  padding-left: 8px !important;
  padding-right: 8px !important;
  border-radius: 2px !important;
}

.ant-popover-inner-content {
  border-radius: 0.5px;
}

.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 20px !important;
  width: 10px !important;
  height: 10px !important;
  border-top: 1px solid var(--grey-lighter) !important;
  border-left: 1px solid var(--grey-lighter) !important;
  box-shadow: none !important;
  background-color: var(--white) !important;
  top: 5px !important;
}

.ant-popover-placement-bottomRight {
  padding-top: 0 !important;
}

.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 20px !important;
  width: 10px !important;
  height: 10px !important;
  border-top: 1px solid var(--grey-lighter) !important;
  border-left: 1px solid var(--grey-lighter) !important;
  box-shadow: none !important;
  background-color: var(--white) !important;
  top: -5px !important;
}

.ant-popover-inner {
  border: 1px solid var(--grey-lighter) !important;
}

.ant-form-item-no-colon {
  font-size: 11px;
  font-weight: medium;
}

.ant-table-filter-icon {
  color: var(--secondary) !important;
}

.ant-drawer-content {
  background: var(--grey-lighter) !important;
}
.ant-btn-primary {
  background-color: var(--secondary) !important;
  color: white !important;
  width: 124px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.ant-select-selection__placeholder {
  opacity: 0.6;
}

.ant-calendar-picker-input::-webkit-input-placeholder,
.ant-calendar-range-picker-input::-webkit-input-placeholder {
  color: var(--secondary) !important;
  opacity: 1;
  font-weight: 400;
}

.ant-calendar-picker-input,
.ant-calendar-range-picker-input {
  font-weight: 500;
}

.ant-calendar-time-picker-btn {
  text-transform: capitalize;
}

.ant-calendar-ok-btn {
  text-transform: uppercase;
}
